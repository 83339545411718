<template>
  <div class="">
    <el-dialog
      title="VN GATE"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <el-form :model="form" ref="form">
        <el-form-item :label="$t('gate.sort')" :label-width="formLabelWidth">
          <el-select v-model="form.cardType" placeholder="">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('gate.serialNumber')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.cardSerial"
            :on-change="
              (form.cardSerial = form.cardSerial.replace(/[^\w_]/g, ''))
            "
            :placeholder="$t('gate.enterSerialNumber')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('gate.cardNumber')"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.cardCode"
            :on-change="(form.cardCode = form.cardCode.replace(/[^\w_]/g, ''))"
            :placeholder="$t('gate.enterCardNumber')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div style="color: red; margin-right: 10px">
          <i class="el-icon-warning"></i> 250 VND = 1 coin
        </div>
        <el-button type="primary" @click="confirm">
          {{ $t("gate.topUp") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      options: [
        // {
        // value: "GATE",
        //label: "GATE",
        // },
        {
          value: "MGCode",
          label: "MGCode",
        },
      ],
      form: {
        cardType: "MGCode",
        cardSerial: "",
        cardCode: "",
      },
      formLabelWidth: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm: debounce(function () {
      if (!this.form.cardSerial || !this.form.cardCode) {
        this.$message.warning("please input card code and card serial!");
        return;
      }
      let params = this.form;
      // params.cardType = "GATE";
      if (this.$route.path === "/purchase/index") {
        this.$http.post("/purchase/cardPay", params).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(
              this.$t("gate.topUpSuccess") + res.data.data.coin + "coin"
            );
            this.form = {
              cardType: "MGCode",
              cardSerial: "",
              cardCode: "",
            };
            // this.$parent.getProductDetail();
            this.$parent.getGateData();
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$http.post("/pay/cardPay", params).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(
              this.$t("gate.topUpSuccess") + res.data.data.coin + "coin"
            );
            this.form = {
              cardType: "MGCode",
              cardSerial: "",
              cardCode: "",
            };
            this.$parent.getGateData();
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    }, 300),
    handleClose() {
      this.form = {
        cardType: "MGCode",
        cardSerial: "",
        cardCode: "",
      };
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/ .el-form-item__label {
  text-align: left;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/deep/ .el-select {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
}
</style>
