<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      :title="$t('Landing.choosePlatform')"
      :width="$isMobile ? '350px' : '500px'"
    >
      <div class="choosePlatformDialog">
        <div
          class="choosePlatform"
          v-for="(item, index) in gameInfo.list"
          :key="index"
        >
          <!-- <el-button
            v-if="item.platform"
            @click="handleClick(item)"
            class="choosePlatformBtn"
          >
            {{ platformNames[item.platform] }}
          </el-button> -->
          <div
            class="choosePlatformBtn"
            v-if="item.platform"
            @click="handleClick(item)"
          >
            <img :src="platformNames[item.platform].src" alt="" />
            <div>{{ platformNames[item.platform].name }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      gameInfo: {},
      platformNames: {
        1: { name: "Android", src: require("../assets/google.png") },
        2: { name: "IOS", src: require("../assets/ios.png") },
        3: { name: "Web", src: require("../assets/web.png") },
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose() {
      this.dialogFormVisible = false;
    },
    handleClick(e) {
      this.$router.push({
        path: "/login",
        query: {
          g: e.game_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 16px !important;
}

/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;

  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}

.choosePlatformDialog {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.choosePlatform {
  display: flex;
}

.choosePlatformBtn {
  // width: 100px;
  font-weight: bolder;
  // border: 3px solid #dcdfe6;
  text-align: center;
  cursor: pointer;
}
.choosePlatformBtn img {
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 1000px) {
  .choosePlatformBtn {
    margin: 0;
  }
}
</style>
